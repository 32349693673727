/* Custom Calendar Styles */
.react-calendar {
    width: 45%;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 34px;
    color: white;
    background-color: #00A762;
    border-radius: 15px;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background-color: '#00A762';
    border: none;
    cursor: pointer;
    color: white; /* Tailwind gray-500 */
    padding: 4px 10px;
  }

  
  .react-calendar__month-view__weekdays {
    
    text-align: center;
    font-weight: bold;
    font-size: 0.75em;
    color: black; /* Tailwind gray-500 */
    
  }

  
  
  .react-calendar__month-view__days__day--weekend {
    color: grey; /* Tailwind red-600 */
  }
  
  .react-calendar__tile {
    max-width: 100%;
    margin-block: 13px;
    padding: 5px 4px;
    background: none;
    text-align: center;
    line-height: 16px;
  }
  
  .react-calendar__tile:enabled:focus {
    background: #e0f2f1; /* Tailwind green-100 */
    color: #004629; /* Tailwind green-900 */
  }
  
  .react-calendar__tile--now {
    background: #b2f5ea; /* Tailwind teal-100 */
    color: #319795; /* Tailwind teal-500 */
  }
  
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #81e6d9; /* Tailwind teal-300 */
  }
  
  .react-calendar__tile--active {
    background: #38a169; /* Tailwind green-600 */
    color: white;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #2f855a; /* Tailwind green-700 */
    color: white;
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6fffa; /* Tailwind teal-50 */
  }
  .react-calendar__month-view__weekdays__weekday {
    text-decoration: none;
  }
  