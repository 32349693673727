.back-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 8px;
    background-color: #00A762; 
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #0dd280; /* Darker blue on hover */
  }
  
  .icon {
    margin-right: 5px; /* Space between icon and text */
  }
  